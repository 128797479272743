import React, { useState, useEffect } from 'react';
import Autocomplete from './Autocomplete';
import './MainSearchPage.css';


function MainSearch() {
  const [selectedWords, setSelectedWords] = useState(['', '', '']);
  const [placeholders, setPlaceholders] = useState(['', '', '']);

  const placeholderTexts = ['World', 'Wide', 'Words'];

  // using states to track the differences in api responses (existing or not)
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [addedUrl, setAddedUrl] = useState('');  // different state for immediately after adding url

  const [url, setUrl] = useState('');  // To track the user input URL

  const handleWordSelect = (word, index) => {
    const newSelectedWords = [...selectedWords];
    newSelectedWords[index] = word;
    setSelectedWords(newSelectedWords);
  };

  // effect to fill in words on page load
  useEffect(() => {
    let timeoutIds = [];

    placeholderTexts.forEach((text, index) => {
      let fullPlaceholder = '';
      text.split('').forEach((char, charIndex) => {
        const timeoutId = setTimeout(() => {
          fullPlaceholder += char;
          setPlaceholders((prev) => {
            const newPlaceholders = [...prev];
            newPlaceholders[index] = fullPlaceholder;
            return newPlaceholders;
          });
        }, (index * 500) + (charIndex * (100))); // 500ms delay per word, 100ms per character
        timeoutIds.push(timeoutId);
      });
    });

    // cleanup
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []); // empty dependency array makes this run once during startup

  // API request when all three words are selected
  useEffect(() => {
    const allWordsSelected = selectedWords.every((word) => word !== '');

    if (allWordsSelected) {
      setAddedUrl(''); // reset added url
      console.log('All three words selected:', selectedWords);

      const fetchOutput = async () => {
        try {
          const response = await fetch(`/api/search/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              words: selectedWords,
            }),
          });

          const data = await response.json();  // Parse the response from the server
          console.log('API response:', data);  // Handle the response here (log it, update state, etc.)
          setApiResponse(data);

        } catch (error) {
          console.error('Error fetching output:', error);  // Handle any errors here
        }
      };

      fetchOutput();
    }
  }, [selectedWords]); // effect is triggered everytime selectedWords is changef

  // Function to handle URL input and validation
  const handleUrlChange = (event) => {
    const inputUrl = event.target.value;
    setUrl(inputUrl);

    // Basic URL validation
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // Protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Port and path
      '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
      '(\\#[-a-zA-Z\\d_]*)?$', 'i');  // Fragment locator

    const isValid = urlPattern.test(inputUrl);
    setIsValidUrl(isValid); // Update state

    // Directly set the border color via inline styles
    event.target.style.borderBottomColor = inputUrl.length === 0
      ? '#ced4da' // Default color for empty input (white/gray)
      : isValid
        ? '#4cbfa4' // Green if valid
        : '#bf4c4c'; // Red if invalid
  };

  // Handle submit new URL
  const handleSubmitUrl = async () => {
    if (isValidUrl) {
      try {
        const response = await fetch(`/api/add/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            words: selectedWords,  // Array of the three selected words
            url: url,  // The new URL provided by the user
          }),
        });

        const data = await response.json();  // Parse the response from the server
        console.log('URL submitted successfully:', data);
        if (data.success) {
          setAddedUrl(url);  // Store the added URL in state
        }

        // Optionally handle success, e.g., show a success message to the user
      } catch (error) {
        console.error('Error submitting URL:', error);
        // Optionally handle error, e.g., show an error message to the user
      }
    }
  };

  // Helper function to ensure URL starts with http:// or https://
  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return 'http://' + url;  // Default to http if no protocol is provided
    }
    return url;
  };

  return (
    <div className="main-container d-flex flex-column justify-content-center align-items-center" style={{ padding: 0 }}>
      <div className="row w-100 mb-4">
        {selectedWords.map((word, index) => (
          <div className="col-md-4 d-flex flex-column justify-content-center www-input-group" key={index + "-bootstrap-wrapper"}>
            <Autocomplete
              key={index}
              onSelect={(word) => handleWordSelect(word, index)}
              placeholder={placeholders[index]}  // Use the animated placeholders
            />
          </div>
        ))}
      </div>

      {apiResponse && (
        <div className="row w-100 text-center">
          {addedUrl ? (
            // Show the success message with the added URL
            <div className="exists-container response">
              <p>Added URL: <a href={ensureProtocol(addedUrl)} target="_blank" rel="noopener noreferrer">{addedUrl}</a></p>
            </div>
          ) : (
            apiResponse.exists ? (
              <div className="exists-container response">
                <p>URL exists for this combination: <br></br><a href={ensureProtocol(apiResponse.url)} target="_blank" rel="noopener noreferrer">{apiResponse.url}</a></p>
              </div>
            ) : (
              <div className="new-url-form response">
                <p>This combination is not taken yet. If you want you can add a URL:</p>
                <input
                  type="text"
                  value={url}
                  onChange={handleUrlChange}
                  placeholder="Enter URL"
                  className="url-input"

                />
                <button
                  onClick={handleSubmitUrl}
                  disabled={!isValidUrl}
                  className="submit-button"
                >
                  Submit
                </button>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );


}

export default MainSearch;
