import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import MainSearchPage from './components/MainSearchPage';
import ImpressumPage from './components/ImpressumPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

function App() {
  return (
    <Router>
      <header className="header">
        <Link to="/" className='logo'>
          3W
        </Link>
      </header>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainSearchPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
        </Routes>
      </div>
      <footer className="footer">
        <div className="text-center py-1">
          <div class="icon-container">
            <a href="/" class="button-icon">
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </a>
            <a href="/impressum" class="button-icon">
              <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            </a>
          </div>
          <div class="copyright">
            <p>&copy; Mark Schutera & Stefan Bühler. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </Router>
  );
}

export default App;
